class EnableRockwellVoucher {
  constructor() {
    this.checkbox = document.querySelector(
      '[data-behavior="enable-rockwell-vouchers-checkbox"]',
    );
    this.voucherGroup = document.querySelector(
      '[data-behavior="enable-rockwell-vouchers-group"]',
    );

    this.toggleVoucherGroup = this.toggleVoucherGroup.bind(this);
  }

  init() {
    this.toggleVoucherGroup();
    this.bindEventListeners();
  }

  bindEventListeners() {
    this.checkbox.addEventListener('change', this.toggleVoucherGroup);
  }

  toggleVoucherGroup() {
    if (this.checkbox.checked) {
      this.voucherGroup.style.display = 'block';
    } else {
      this.voucherGroup.style.display = 'none';
    }
  }
}

export default EnableRockwellVoucher;
