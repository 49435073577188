export const getChatRooms = async () => {
  const response = await fetch('/chat_rooms?page=1');
  const chatRooms = await response.json();
  return chatRooms;
};

export const getChatRoom = async (id) => {
  const response = await fetch(`/chat_rooms/${id}`, {
    headers: {
      Accept: 'application/json',
    },
  });
  const chatRoom = await response.json();
  return chatRoom;
};
