class Countdown {
  constructor() {
    this.futureExams = document.querySelectorAll(
      '[data-countdown-available="true"]'
    );
    this.startUpButton = document.querySelector(
      '[data-behavior="start"]:not(.d-none)'
    );
    this.countdownContainer = document.querySelector(
      ".js-row-panel-countdown"
    )
    this.countdownText = $(".js-countdown-text");
    this.countdownClock = $(".js-countdown-clock");
    this.escalationMessage = $(".escalation-message");
    this.examsCounter = 0;
    this.closestReservation = document.querySelector(
      '[data-behavior="closest-reservation"]'
    );
    this.displayStartButton = this.displayStartButton.bind(this);
    this.displayTimeLeft = this.displayTimeLeft.bind(this);
  }

  init() {
    if (!this.closestReservation)
      $(".js-row-panel-countdown").remove();
    else if (this.startUpButton)
      this.displayStartButton(this.startUpButton);
    else
      this.startCountdown(this.closestReservation);
  }

  displayStartButton(startButton) {
    this.countdownText.html(`<strong>${polyglot.t('ready_to_go')}<strong>`);
    startButton.classList.remove("d-none");
    let startButtonClone = startButton.cloneNode(true);
    startButtonClone.classList.add("btn-start", "mt-3");
    this.countdownClock.html(startButtonClone);

    const examRow = startButton.closest('tr');
    if (examRow.getAttribute("data-escalated")) {
      this.escalationMessage.html(polyglot.t('escalated'));
    }
    if (startButton.text === "Reconnect") {
      let rescheduleButtons = examRow.querySelectorAll(
        '[data-behavior="reschedule"]'
      );
      [...rescheduleButtons].forEach(button => {
        button.classList.add("d-none");
      });
    }
    const expirationDate = examRow.getAttribute("data-expiration-time");
    const timeout = Math.round(expirationDate - Date.parse(new Date()));
    setTimeout(
      () => this.makeExamUnavailable(examRow, startButton, startButtonClone),
      timeout
    );
    setTimeout(() => this.startNextCountdown(), timeout);
  }

  makeExamUnavailable(examRow, startButton, startButtonClone) {
    startButtonClone.remove();
    if (examRow.getAttribute("data-enable-reschedule")) {
      startButton.remove();
      let rescheduleButtons = examRow.querySelectorAll(
        '[data-behavior="reschedule"]'
      );
      [...rescheduleButtons].forEach(button => {
        button.classList.remove("d-none");
      });
    } else {
      examRow.remove();
    }
  }

  startNextCountdown() {
    let nextExam = this.futureExams[this.examsCounter];
    if (nextExam) {
      this.startCountdown(nextExam);
    } else {
      const startButtons = document.querySelectorAll('[data-behavior="start"]');
      if (startButtons.length === 0) {
        $(".js-row-panel-countdown").remove();
      } else {
        this.displayStartButton(startButtons[0]);
      }
      this.displayBlankState();
    }
  }

  startCountdown(examRow) {
    if (!examRow) return;
  
    this.escalationMessage.empty();
  
    this.countdownText.html(`<strong>${polyglot.t('countdown_to_exam')}:</strong>`);
    this.countdownClock.html(`<div class="row h-100 align-items-center text-uppercase text-center">
                                    <span class="col-sm-3">
                                      <span class="h1 days"></span>
                                      <div>
                                        <small>${polyglot.t('datetime_prompts_days')}</small>
                                      </div>
                                    </span>
                                    <span class="col-sm-3">
                                      <span class="h1 hours"></span>
                                      <div>
                                        <small>${polyglot.t('datetime_prompts_hours')}</small>
                                      </div>
                                    </span>
                                    <span class="col-sm-3">
                                      <span class="h1 minutes"></span>
                                      <div>
                                        <small>${polyglot.t('datetime_prompts_minutes')}</small>
                                      </div>
                                    </span>
                                    <span class="col-sm-3">
                                      <span class="h1 seconds"></span>
                                      <div>
                                        <small>${polyglot.t('datetime_prompts_seconds')}</small>
                                      </div>
                                    </span>
                                  </div>
                                `);
  
    const countdownDateElement = examRow.querySelector('[data-behavior="countdown-dates"]');

    // actual date and time of the exam
    const countdownDate = new Date(countdownDateElement.dataset.date);

    // adjusted date and time of the exam (usually 3 minutes before the actual exam)
    const countdownAdjustedDate = new Date(countdownDateElement.dataset.adjustedDate);

    let systemTime = moment.utc().toDate();
  
    if (countdownDate <= new Date()) {
      this.examsCounter += 1;
      return this.startNextCountdown();
    }
  
    this.displayTimeLeft(systemTime, countdownDate);
  
    const timeInterval = setInterval(() => {
      systemTime = moment.utc().toDate();
      this.displayTimeLeft(systemTime, countdownDate);
  
      if (this.getDuration(systemTime, countdownAdjustedDate).asSeconds() <= 0) {
        clearInterval(timeInterval);
        this.examsCounter += 1;
        const uuid = examRow.dataset.reservation;
        const visibleExamRow = document.querySelector(`[data-reservation="${uuid}"]:not(.d-none)`);
  
        this.displayStartButton(
          visibleExamRow.querySelector('[data-behavior="d-nonestart"]')
        );
      }
    }, 1000);
  }

  displayBlankState() {
    if (document.querySelectorAll(".js-exam-row").length === 0) {
      const allowScheduling = $(".js-exams-table").data("allowing-schedule");
      const blankslate = `<tr>
          <td class="table-full" colspan="4">
            You currently do not have any sessions scheduled.
            ${this.renderAllowSchedule(allowScheduling)}
          </td>
        </tr>
        `;
      $(".js-exams-table tbody").html(blankslate);
    }
  }

  renderAllowSchedule(allowScheduling){
    if (allowScheduling) {
      return `Click "Schedule New Session" to add your next session.
      <a href="/students/exams/select" class="btn btn-secondary">
        Schedule New Session
      </a>`;
    } else { return ''}
  }

  getDuration(startTime, endTime) {
    const start = moment(startTime);
    const end = moment(endTime);
    
    return moment.duration(end.diff(start));
  }

  displayTimeLeft(startTime, endTime) {
    const duration = this.getDuration(startTime, endTime);
    
    const padZero = (num) => num.toString().padStart(2, '0');
    
    $(".days").html(padZero(duration.days()));
    $(".hours").html(padZero(duration.hours()));
    $(".minutes").html(padZero(duration.minutes()));
    $(".seconds").html(padZero(duration.seconds()));
  }
}

export default Countdown;
